import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const useAnimationTimer = (count = 5) => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (step === count) {
        return setStep(1);
      }
      setStep(step + 1);
    }, 3500);

    return () => clearInterval(interval);
  }, [step]);

  return { step };
};

const Animation = ({ images }: { images?: string[] }) => {
  const { step } = useAnimationTimer(images.length);
  const imgSrc = images[step] ? images[step] : images[0];
  return (
    <Wrapper>
      <Image src={imgSrc} alt="What they say" />
    </Wrapper>
  );
};

export default Animation;
