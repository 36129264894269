import React, { useEffect, useState, lazy, Suspense } from 'react';
import styled from 'styled-components/macro';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql, Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { ToolsIntl } from '../utils/intl';
import Animation from '../components/hero/Animation';
import '../styles/main.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Step from '../components/step';
import Section from '../components/section';

import Dropdown from '../components/insightsDropdown';
import Testimony from '../components/testimony';
import {
  PricingContext,
  getLowestToolPriceInWorkbench,
  currencySymbols,
  getCurrencyName,
} from '../providers/PricingProvider'; //

import videoPoster from '../assets/images/Video.jpg';
import 'react-multi-carousel/lib/styles.css';
import SocialProof from '../components/socialProof';

const TwoColumnSection = lazy(() => import('../components/twoColumnSection'));
const Card = lazy(() => import('../components/card'));
const Carousel = lazy(() => {
  return import('react-multi-carousel');
});

const ENV =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

let APP_URL = '';

switch (ENV) {
  case 'local':
    APP_URL = 'localhost:3000';
    break;
  case 'development':
    APP_URL = 'app-dev.deeptrue.com';
    break;
  default:
    APP_URL = 'app.deeptrue.com';
    break;
}

type TestimonialType = {
  comment: string;
  name: string;
  role: string;
  img: {
    url: string;
  };
  company_logo: {
    url: string;
  };
};

type DecisionType = {
  item_name: string;
  item_description: string;
};

type IndexProps = {
  pageContext: {
    language: string;
    heroDropdown: ToolsIntl;
  };
  data: any;
};

const videos = {
  en: {
    mp4: `${process.env.GATSBY_URL_STATIC}/assets/deeptrue_en_low.mp4`,
    // ogg: videoEnOgv,
    // webm: videoEnWebm,
  },
  de: {
    mp4: `${process.env.GATSBY_URL_STATIC}/assets/deeptrue_de_low.mp4`,
    // ogg: videoDeOgv,
    // webm: videoDeWebm,
  },
  it: {
    mp4: `${process.env.GATSBY_URL_STATIC}/assets/deeptrue_en_low.mp4`,
    // ogg: videoEnOgv,
    // webm: videoEnWebm,
  },
  fr: {
    mp4: `${process.env.GATSBY_URL_STATIC}/assets/deeptrue_en_low.mp4`,
    // ogg: videoEnOgv,
    // webm: videoEnWebm,
  },
};

const Paragraph = styled.p`
  font-size: 1rem;
  color: #353531;
  line-height: 1.5;
  font-weight: 400;
  margin: 0;
`;

const StyledSection = styled(Section)`
  background-color: #f6f9fe;
  padding-top: 1.5rem;
  margin-bottom: 4.875rem;
  margin-top: 3rem;
`;
const IndexPage = (props: IndexProps) => {
  const intl = useIntl();
  const [insight, setInsight] = useState(null);
  const { allPrismicHomepage, allPrismicCommonInformation } = props.data;

  const [step, setStep] = useState(0);

  const {
    hero_texts,
    hero_cta_text,
    hero_placeholder,
    title,
    main_title: mainTitle,
    dropdown_items,
    items: testimonials,
    section_subtitle: testimonialsSubtitle,
    section_title: testimonialsTitle,
    decisions_title: decisionsTitle,
    decisions_subtitle: decisionsSubtitle,
    decisions_list: decisions,
    branding_cta_text,
    branding_cta_url,
    branding_description,
    branding_title,
    experience_cta_text,
    experience_cta_url,
    experience_description,
    experience_title,
    how_description,
    how_first_counter,
    how_first_description,
    how_first_tagline,
    how_first_title,
    how_second_counter,
    how_second_description,
    how_second_tagline,
    how_second_title,
    how_third_counter,
    how_third_description,
    how_third_tagline,
    how_third_title,
    how_title,
    innovation_cta_text,
    innovation_cta_url,
    innovation_description,
    innovation_title,
    tools_tagline,
    tools_title,
    is_selected_features_visible,
    selected_features_title,
    selected_features_description,
    sections,
    social_proof,
    hero_images,
  } = allPrismicHomepage.edges[0].node.data;
  const commonData = allPrismicCommonInformation.edges[0].node.data;
  const heroImages = hero_images.map(({ hero_image }) => hero_image.url);

  const options =
    dropdown_items &&
    dropdown_items.map((tool) => ({
      value: tool.product_slug,
      text: tool.text,
    }));

  useEffect(() => {
    const interval = setInterval(() => {
      if (step === 6) {
        return setStep(1);
      }
      setStep(step + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, [step]);

  useEffect(() => {
    const player = document.querySelector('.video video');
    const threshold = 0.25;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio >= threshold) {
          player.play();
        } else {
          player.pause();
        }
      },
      {
        threshold: 0.25,
      }
    );

    observer.observe(player);
  }, []);

  const textToDisplay = hero_texts[step]?.text1;

  return (
    <PricingContext.Consumer>
      {({ toolsPricing, pricingData }) => {
        if (!toolsPricing || !pricingData) return null;
        return (
          <Layout lang={props.pageContext.language}>
            <SEO title="Home" lang={props.pageContext.language} />
            <Section className="hero hero--home">
              <div className="hero__wrap">
                <div className="hero__content">
                  <h1 className="hero__title">{mainTitle}</h1>
                  <p className="hero__description">
                    {textToDisplay}
                    <br />
                    {title}
                  </p>
                  <form className="hero__form">
                    {options && options.length > 0 && (
                      <Dropdown
                        className="hero__dropdown"
                        onChange={(value) => setInsight(value)}
                        placeholder={hero_placeholder}
                        options={options}
                      />
                    )}
                    <Link
                      to={insight ? insight : options[0]?.value}
                      className="hero__button"
                      hrefLang={intl.locale}
                    >
                      {hero_cta_text}
                    </Link>
                  </form>
                </div>
                <div
                  className="hero__cover"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <Animation images={heroImages} />
                </div>
              </div>
            </Section>
            <StyledSection>
              <SocialProof logos={social_proof} />
            </StyledSection>
            <Section
              title={how_title}
              description={how_description}
              className="how"
            >
              <Step
                className="how__step"
                name={how_first_tagline}
                title={how_first_title}
                counter={how_first_counter}
              >
                <Paragraph>{how_first_description}</Paragraph>
              </Step>
              <Step
                className="how__step"
                name={how_second_tagline}
                title={how_second_title}
                counter={how_second_counter}
              >
                <Paragraph>{how_second_description}</Paragraph>
              </Step>
              <Step
                className="how__step"
                name={how_third_tagline}
                title={how_third_title}
                counter={how_third_counter}
              >
                <Paragraph>{how_third_description}</Paragraph>
              </Step>
            </Section>
            <Section className="video">
              <video loop poster={videoPoster} muted preload="none">
                <source
                  src={videos[props.pageContext.language].mp4}
                  type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                />
                {/* <source
                  src={videos[props.pageContext.language].webm}
                  type="video/webm"
                />
                <source
                  src={videos[props.pageContext.language].ogg}
                  type="video/ogg"
                /> */}
              </video>
            </Section>
            <Section
              tagline={tools_tagline}
              title={tools_title}
              className="tools"
            >
              <Suspense fallback={null}>
                <Card
                  iconVariant="landing_innovation"
                  title={innovation_title}
                  description={innovation_description}
                  cta_label={innovation_cta_text}
                  cta_url={innovation_cta_url}
                  price={`${Math.round(
                    getLowestToolPriceInWorkbench(toolsPricing, 'Innovation') *
                      pricingData.currencyRates[
                        getCurrencyName(pricingData.currentCurrency)
                      ]
                  )} ${
                    currencySymbols[
                      getCurrencyName(pricingData.currentCurrency)
                    ]
                  }`}
                  starting_at_text={commonData.pricing_starting_at_text}
                  per_tool_text={commonData.pricing_per_tool_text}
                />

                <Card
                  iconVariant="landing_experience"
                  title={experience_title}
                  description={experience_description}
                  cta_label={experience_cta_text}
                  cta_url={experience_cta_url}
                  price={`${Math.round(
                    getLowestToolPriceInWorkbench(
                      toolsPricing,
                      'Customer Experience'
                    ) *
                      pricingData.currencyRates[
                        getCurrencyName(pricingData.currentCurrency)
                      ]
                  )} ${
                    currencySymbols[
                      getCurrencyName(pricingData.currentCurrency)
                    ]
                  }`}
                  starting_at_text={commonData.pricing_starting_at_text}
                  per_tool_text={commonData.pricing_per_tool_text}
                />

                <Card
                  iconVariant="landing_branding"
                  title={branding_title}
                  description={branding_description}
                  cta_label={branding_cta_text}
                  cta_url={branding_cta_url}
                  price={`${Math.round(
                    getLowestToolPriceInWorkbench(toolsPricing, 'Branding') *
                      pricingData.currencyRates[
                        getCurrencyName(pricingData.currentCurrency)
                      ]
                  )} ${
                    currencySymbols[
                      getCurrencyName(pricingData.currentCurrency)
                    ]
                  }`}
                  starting_at_text={commonData.pricing_starting_at_text}
                  per_tool_text={commonData.pricing_per_tool_text}
                />
              </Suspense>
            </Section>
            <Section
              className="decisions"
              title={decisionsTitle}
              description={decisionsSubtitle}
            >
              {decisions &&
                decisions.map((decision: DecisionType) => (
                  <Step
                    className="decisions__step"
                    key={decision.item_name}
                    title={decision.item_name}
                    content={decision.item_description}
                  />
                ))}
            </Section>
            <Suspense fallback={null}>
              {is_selected_features_visible && (
                <Section
                  title={selected_features_title}
                  description={selected_features_description}
                  className="tool-page"
                >
                  {sections &&
                    sections.map((section, idx) => (
                      <TwoColumnSection
                        key={`tools-feature-${idx}`}
                        headline={section.feature_headline}
                        description={`<p>${section.feature_description}</p>`}
                        cover={section.image.url}
                        cover_alt={section.feature_headline}
                        toggleDirection={(idx + 1) % 2 === 0}
                        withBackground={(idx + 1) % 2 === 0}
                        cta_label={section.feature_cta_text}
                        cta_url={`https://${APP_URL}/sign-up?lang=${intl.locale}`}
                        externalLink={true}
                      />
                    ))}
                </Section>
              )}
            </Suspense>
            <Section
              className="testimonies"
              tagline={testimonialsTitle}
              title={testimonialsSubtitle}
            >
              <div className="m-carousel">
                <Suspense fallback={null}>
                  <Carousel
                    showDots={true}
                    responsive={{
                      tablet: {
                        breakpoint: { min: 960, max: 8000 },
                        items: 2,
                      },
                      mobile: {
                        breakpoint: { max: 960, min: 0 },
                        items: 1,
                      },
                    }}
                  >
                    {testimonials &&
                      testimonials.map((testimonial: TestimonialType) => (
                        <Testimony
                          key={testimonial.name}
                          name={testimonial.name}
                          position={testimonial.role}
                          quote={testimonial.comment}
                          imageUrl={testimonial.img.url}
                          logoUrl={testimonial.company_logo.url}
                        />
                      ))}
                  </Carousel>
                </Suspense>
              </div>
            </Section>
          </Layout>
        );
      }}
    </PricingContext.Consumer>
  );
};

export const query = graphql`
  query MyQuery($prismicLang: String) {
    allPrismicHomepage(filter: { lang: { eq: $prismicLang } }) {
      edges {
        node {
          _previewable
          data {
            branding_cta_text
            branding_cta_url
            branding_description
            branding_title
            decisions_list {
              item_description
              item_name
            }
            decisions_subtitle
            decisions_title
            dropdown_items {
              product_slug
              text
            }
            experience_cta_text
            experience_cta_url
            experience_description
            experience_title
            hero_cta_text
            hero_placeholder
            hero_images {
              hero_image {
                url
              }
            }
            how_description
            how_first_counter
            how_first_description
            how_first_tagline
            how_first_title
            how_second_counter
            how_second_description
            how_second_tagline
            how_second_title
            how_third_counter
            how_third_description
            how_third_tagline
            how_third_title
            how_title
            innovation_cta_text
            innovation_cta_url
            innovation_description
            innovation_title
            items {
              comment
              img {
                url
              }
              name
              role
              company_logo {
                url
              }
            }
            main_title
            hero_texts {
              text1
            }
            section_subtitle
            section_title
            title
            tools_tagline
            tools_title
            is_selected_features_visible
            selected_features_title
            selected_features_description
            sections {
              image {
                url
              }
              feature_headline
              feature_description
              feature_cta_text
            }
            social_proof {
              logo {
                url
              }
            }
          }
        }
      }
    }
    allPrismicCommonInformation(filter: { lang: { eq: $prismicLang } }) {
      edges {
        node {
          lang
          data {
            pricing_per_tool_text
            pricing_starting_at_text
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(IndexPage);
