import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  background-color: #f6f9fe;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 4.375rem;
  align-items: space-between;
  img {
    margin: 2rem;
    width: 100%;
    height: auto;
  }
  @media (min-width: 960px) {
    flex-direction: row;
    img {
      margin: 0;
      width: 20%;
    }
    padding: 0;
  }
`;

const SocialProof = ({ logos }: any) => {
  return (
    <Wrapper>
      {logos.map(({ logo }, idx) => {
        return <img src={logo.url} key={idx} alt="leads" loading="lazy" />;
      })}
    </Wrapper>
  );
};

export default SocialProof;
